import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LeadList } from 'src/app/shared/models/classes/call';

export const LeadListActions = createActionGroup({
  source: 'LeadList',
  events: {
    'Load LeadLists': props<{ agencyId: string }>(),
    'Load LeadLists Success': props<{ leadLists: LeadList[] }>(),
    'Load LeadLists Failure': props<{ error: any }>(),
    'Load LeadList': props<{ leadListId: string; agencyId: string }>(),
    'Load LeadList Success': props<{ leadList: LeadList }>(),
    'Load LeadList Failure': props<{ error: any }>(),
    'Add LeadList': props<{ leadList: LeadList; agencyId: string }>(),
    'Add LeadList Success': props<{ leadList: LeadList }>(),
    'Add LeadList Failure': props<{ error: any }>(),
    'Remove LeadList': props<{ leadListId: string; agencyId: string }>(),
    'Remove LeadList Success': props<{ leadListId: string }>(),
    'Remove LeadList Failure': props<{ error: any }>(),
    'Update LeadList': props<{
      leadListId: string;
      agencyId: string;
      leadList: Partial<LeadList>;
    }>(),
    'Update LeadList Success': props<{
      leadListId: string;
      agencyId: string;
      leadList: Partial<LeadList>;
    }>(),
    'Update LeadList Failure': props<{ error: any }>(),
  },
});
